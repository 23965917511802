import { Link as ScrollLink } from 'react-scroll';
import { Link as RouterLink } from 'react-router-dom';

import { Icon } from '@iconify/react';
import instagramFill from '@iconify/icons-ant-design/instagram-fill';
import facebookFill from '@iconify/icons-eva/facebook-fill';
import whatsappIcon from '@iconify/icons-ant-design/whats-app-outlined';
// material
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Stack from '@material-ui/core/Stack';
import { styled } from '@material-ui/core/styles';

// routes
import Logo from '../../components/Logo';
import { PATH_PAGE } from '../../routes/paths';

// ----------------------------------------------------------------------

const SOCIALS = [
  {
    name: 'FaceBook',
    icon: facebookFill,
    link: 'https://www.facebook.com/OkRabbit'
  },
  {
    name: 'Instagram',
    icon: instagramFill,
    link: 'https://www.instagram.com/okrabbit_mx/'
  },
  {
    name: 'Whatsapp',
    icon: whatsappIcon,
    link: 'https://wa.me/5215513456465'
  }
];

const LINKS = [
  {
    headline: 'Legal',
    children: [
      { name: 'Preguntas Frecuentes', href: PATH_PAGE.faqs },
      { name: 'Términos y Condiciones', href: PATH_PAGE.termsAndConditions },
      { name: 'Aviso de Privacidad', href: PATH_PAGE.privacyPolicy }
    ]
  }
];

const RootStyle = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.default
}));

// ----------------------------------------------------------------------

export default function MainFooter() {
  return (
    <RootStyle>
      <Divider />
      <Container maxWidth="lg" sx={{ pt: 10 }}>
        <Grid
          container
          justifyContent={{ xs: 'center', md: 'space-between' }}
          sx={{ textAlign: { xs: 'center', md: 'left' } }}
        >
          <Grid item xs={12} sx={{ mb: 3 }}>
            <ScrollLink to="move_top" spy smooth>
              <Logo sx={{ mx: { xs: 'auto', md: 'inherit' } }} />
            </ScrollLink>
          </Grid>
          <Grid item xs={8} md={3}>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              En OKRabbit puedes participar en múltiples sorteos y ganar
              increíbles premios
            </Typography>
            <Typography variant="body2" sx={{ pr: { md: 5 } }}>
              ¡Participa y #SaltaHaciaTuSuerte!
            </Typography>

            <Stack
              spacing={1.5}
              direction="row"
              justifyContent={{ xs: 'center', md: 'flex-start' }}
              sx={{ mt: 5, mb: { xs: 5, md: 0 } }}
            >
              {SOCIALS.map((social) => (
                <a
                  key={social.name}
                  href={social.link}
                  target="_blank"
                  rel="noreferrer"
                >
                  <IconButton color="primary" sx={{ p: 1 }}>
                    <Icon icon={social.icon} width={16} height={16} />
                  </IconButton>
                </a>
              ))}
            </Stack>
          </Grid>

          <Grid item xs={12} md={5}>
            <Stack
              spacing={5}
              direction={{ xs: 'column', md: 'row' }}
              justifyContent="space-between"
            >
              {LINKS.map((list) => {
                const { headline, children } = list;
                return (
                  <Stack key={headline} spacing={2}>
                    <Typography component="p" variant="overline">
                      {headline}
                    </Typography>
                    {children.map((link) =>
                      link.href ? (
                        <Link
                          component={RouterLink}
                          to={link.href}
                          key={link.name}
                          color="inherit"
                          variant="body2"
                          sx={{ display: 'block' }}
                        >
                          {link.name}
                        </Link>
                      ) : (
                        <Typography key={link.name} variant="body2">
                          {link.name}
                        </Typography>
                      )
                    )}
                  </Stack>
                );
              })}

              <Stack spacing={2}>
                <Typography component="p" variant="overline">
                  Contacto OkRabbit
                </Typography>

                <Link
                  href="mailto:socios@okrabbit.mx"
                  color="inherit"
                  variant="body2"
                  target="_blank"
                  sx={{ display: 'block' }}
                >
                  socios@okrabbit.mx
                </Link>
                <Link
                  href="tel:5513456465"
                  color="inherit"
                  variant="body2"
                  sx={{ display: 'block' }}
                >
                  Cel: 55 1345 6465
                </Link>
                <Typography>CDMX, Roma Norte, CP 06700</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>

        <Typography
          component="p"
          variant="body2"
          sx={{
            mt: 10,
            pb: 5,
            fontSize: 13,
            textAlign: { xs: 'center', md: 'left' }
          }}
        >
          © 2021. Todos los derechos reservados
        </Typography>
      </Container>
    </RootStyle>
  );
}
