import jwtDecode from 'jwt-decode';
import { createSlice } from '@reduxjs/toolkit';
import { auth, googleAuthProvider, facebookAuthProvider } from '../../firebase';
// utils
import { injextAxiosHeaders } from '../../api/axios';
import authApi from '../../api/auth';
import userApi from '../../api/users';
import partnerApi from '../../api/partners';

import { ACCOUNT_TYPES } from '../../config';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: null
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },

    localUserUpdated(state, action) {
      state.user = action.payload;
    },

    // LOGIN
    loginSuccess(state) {
      state.isAuthenticated = true;
    },

    signupSuccess(state) {
      state.isAuthenticated = true;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
    },

    avatarUpdated(state, action) {
      const avatar = action.payload;
      state.user.avatar = avatar;
    },

    coverUpdated(state, action) {
      const cover = action.payload;
      state.user.cover = cover;
    },

    /* GOOGLE AUTHENTICATION */
    googleAuthStarted(state) {
      return state;
    },
    googleAuthSuccess(state, action) {
      state.isAuthenticated = true;
    },
    googleAuthFailed(state) {
      return state;
    },

    /* FACEBOOK AUTHENTICATION */
    facebookAuthStarted(state) {
      return state;
    },
    facebookAuthSuccess(state, action) {
      state.isAuthenticated = true;
    },
    facebookAuthFailed(state) {
      return state;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
const {
  startLoading,
  loginSuccess,
  signupSuccess,
  logoutSuccess,
  localUserUpdated,
  avatarUpdated,
  coverUpdated,

  /* GOOGLE AUTHENTICATION */
  googleAuthStarted,
  googleAuthSuccess,
  googleAuthFailed,

  /* FACEBOOK AUTHENTICATION */
  facebookAuthStarted,
  facebookAuthSuccess,
  facebookAuthFailed
} = slice.actions;

// ----------------------------------------------------------------------

const tokenKeyOnLocalStorage = 'accessToken';

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken) =>
  accessToken
    ? localStorage.setItem(tokenKeyOnLocalStorage, accessToken)
    : localStorage.removeItem(tokenKeyOnLocalStorage);

const getUserFromToken = (accessToken) => {
  const { user_id, name, last_name, email, phone } = jwtDecode(accessToken);
  return {
    id: user_id,
    last_name,
    name,
    email,
    phone
  };
};

// ----------------------------------------------------------------------
// ACTION CREATORS
export const login = ({ email, password }) => {
  return async (dispatch) => {
    const response = await authApi.login({ email, password });
    const { success, message, token } = response.data;
    if (!success) {
      throw Error(message);
    }

    setSession(token);
    injextAxiosHeaders();
    dispatch(loginSuccess());
  };
};

export function logout() {
  return async (dispatch) => {
    auth.signOut();
    setSession(null);
    dispatch(logoutSuccess());
  };
}

export const signup = (data) => {
  return async (dispatch) => {
    const response = await userApi.signup(data);
    const { success, message, token } = response.data;

    if (!success) throw Error(message);

    setSession(token);
    injextAxiosHeaders();
    dispatch(signupSuccess());

    return response;
  };
};

/* This endpoint logic is wrong, backend team should need to look into this endpoint struture */
export const updateMe = (myId, data) => {
  return async (dispatch, getState) => {
    try {
      const response = await authApi.updateMe(myId, data);

      /* Custom onboarding behavior */
      const { onboarding } = response.data;
      if (onboarding === true) {
        data.onboarding = true;
      } else {
        data.onboarding = false;
      }

      const {
        authJwt: { user }
      } = getState();
      dispatch(
        localUserUpdated({
          ...user,
          ...data
        })
      );

      return response;
    } catch (error) {
      throw error;
    }
  };
};

/* GOOGLE AUTHENTICATION (SIGNIN AND SIGNUP) */
export const authWithGoogle = (values) => {
  return async function (dispatch) {
    dispatch(googleAuthStarted());

    try {
      const response = await auth.signInWithPopup(googleAuthProvider);
      const { profile, isNewUser } = response.additionalUserInfo;
      const { id: profileId, email, name, family_name } = profile;

      //Make a request to rabbit with user information
      const rabbitResponse = await authApi.authWithSocial({
        email,
        name,
        last_name: family_name,
        google_id: profileId,
        type: values ? values.type : ACCOUNT_TYPES.partner //handle account type creation (only homepage form can create partner accounts)
      });

      const { success, message, token } = rabbitResponse.data;
      // verify login was successfull
      if (!success) {
        dispatch(googleAuthFailed());
        throw message;
      }

      setSession(token);
      injextAxiosHeaders();
      dispatch(googleAuthSuccess(getUserFromToken(token)));

      //Add isNewUser key from google to rabbit response
      rabbitResponse.data.isNewUser = isNewUser;

      return rabbitResponse;
    } catch (error) {
      dispatch(googleAuthFailed());
      throw error;
    }
  };
};

/* FACEBOOK LOGIN */
export const authWithFacebook = (values) => {
  return async function (dispatch) {
    dispatch(facebookAuthStarted());

    try {
      const response = await auth.signInWithPopup(facebookAuthProvider);
      const { profile, isNewUser } = response.additionalUserInfo;
      const { id: profileId, email, first_name, last_name } = profile;

      //Make a request to rabbit with user information
      const rabbitResponse = await authApi.authWithSocial({
        email,
        name: first_name,
        last_name,
        facebook_id: profileId,
        type: values ? values.type : ACCOUNT_TYPES.partner //handle account type creation (only homepage form can create partner accounts)
      });

      const { success, message, token } = rabbitResponse.data;
      // verify is login was successfull
      if (!success) {
        dispatch(facebookAuthFailed());
        throw message;
      }

      setSession(token);
      injextAxiosHeaders();
      dispatch(facebookAuthSuccess(getUserFromToken(token)));

      //Add isNewUser key from google to rabbit response
      rabbitResponse.data.isNewUser = isNewUser;

      return rabbitResponse;
    } catch (error) {
      dispatch(facebookAuthFailed());
      throw error;
    }
  };
};

// ----------------------------------------------------------------------

export function getInitialize() {
  return async (dispatch) => {
    dispatch(startLoading());

    try {
      const accessToken = window.localStorage.getItem(tokenKeyOnLocalStorage);

      if (accessToken && isValidToken(accessToken)) {
        const me = await getMyInformationProfile();
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: me
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}

async function getMyInformationProfile() {
  try {
    const response = await authApi.me();
    const {
      user: { id, name, last_name, email, phone, onboarding },
      partner: {
        amount_goal,
        authorized_donee,
        cause,
        civil_association,
        incorporation_act,
        total_awards,
        label_url
      }
    } = response.data;

    return {
      id,
      name,
      last_name,
      email,
      phone,
      onboarding,
      amount_goal,
      authorized_donee,
      cause,
      civil_association,
      incorporation_act,
      total_awards,
      label_url
    };
  } catch (error) {
    console.log(error);
    throw error;
  }
}

export const uploadAvatar = (file, partnerId) => {
  return async (dispatch) => {
    try {
      const response = await partnerApi.uploadAvatar(file, partnerId);
      const avatar = response.data?.url;
      dispatch(avatarUpdated(avatar));
      return response.data;
    } catch (error) {
      throw error;
    }
  };
};

export const uploadCover = (file, partnerId) => {
  return async (dispatch) => {
    try {
      const response = await partnerApi.uploadCover(file, partnerId);
      const cover = response.data?.url;
      dispatch(coverUpdated(cover));
      return response.data;
    } catch (error) {
      throw error;
    }
  };
};
