import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
// components
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

GuestProtect.propTypes = {
  children: PropTypes.node
};

/* THIS IS USED ONLY FOR login ROUTE, IN CASE THE USER IS LOGGED IN, THE SYSTEM WILL REDIRECT HIM TO THE DASHBOARD */

export default function GuestProtect({ children }) {
  const { isLoading, isAuthenticated } = useAuth();

  if (isLoading) {
    return <LoadingScreen />;
  }

  if (isAuthenticated) {
    return <Redirect to={'/'} />;
  }

  return <>{children}</>;
}
