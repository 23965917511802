import PropTypes from 'prop-types';
import { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// redux
import { getInitialize } from '../../redux/slices/auth';
import { getCards, getSubscriptions } from '../../redux/slices/billing';

// ----------------------------------------------------------------------

JwtProvider.propTypes = {
  children: PropTypes.node
};

export default function JwtProvider({ children }) {
  const dispatch = useDispatch();
  const { authJwt } = useSelector((state) => state);

  /* SETUP INITIAL REQUIRED REQUESTS */
  const initialRequiredRequests = useCallback(() => {
    const { isAuthenticated } = authJwt;

    if (isAuthenticated) {
      dispatch(getCards());
      dispatch(getSubscriptions());
    }
  }, [dispatch, authJwt]);

  useEffect(() => {
    dispatch(getInitialize());
    initialRequiredRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, authJwt.isAuthenticated]);

  return <>{children}</>;
}
