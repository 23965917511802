import { createSlice } from '@reduxjs/toolkit';
import partnersApi from '../../api/partners';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  list: []
};

const slice = createSlice({
  name: 'partners',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    //GET PARTNERS
    getPartnersSuccess(state, action) {
      state.isLoading = false;
      state.list = action.payload;
    },

    //ADD TREES TO PARTNER
    addTreesToPartner(state, action) {
      const { partner, trees } = action.payload;
      const partnerToEdit = state.list.find((item) => item.id === partner.id);
      if (partnerToEdit) partnerToEdit.trees = trees;
    },

    //ADD WINNERS TO PARTNER
    addWinnersToPartner(state, action) {
      const { partner_id, winners } = action.payload;
      const partnerToEdit = state.list.find((item) => item.id === partner_id);
      if (partnerToEdit) partnerToEdit.winners = winners;
    },

    //ADD HOPPERS TO PARTNER
    addHoppersToPartner(state, action) {
      const { partner_id, hoppers } = action.payload;
      const partnerToEdit = state.list.find((item) => item.id === partner_id);
      if (partnerToEdit) partnerToEdit.hoppers = hoppers;
    },

    //ADD RAFFLES TO PARTNER
    addRafflesToPartner(state, action) {
      const { partner_id, raffles } = action.payload;
      const partnerToEdit = state.list.find((item) => item.id === partner_id);
      if (partnerToEdit) partnerToEdit.raffles = raffles;
    },

    //ADD TOKENS TO PARTNER
    addTokensToPartner(state, action) {
      const { partner_id, tokens } = action.payload;
      const partnerToEdit = state.list.find((item) => item.id === partner_id);
      if (partnerToEdit) partnerToEdit.tokens = tokens;
    }
  }
});

// Reducer
export default slice.reducer;

const {
  getPartnersSuccess,
  hasError,
  addTreesToPartner,
  addWinnersToPartner,
  addHoppersToPartner,
  addRafflesToPartner,
  addTokensToPartner
} = slice.actions;

// ----------------------------------------------------------------------
// ACTION CREATORS

/* Load basic partner information */
export const getPartners = (options) => {
  return async (dispatch) => {
    try {
      const response = await partnersApi.getAll({
        raffles: true
      });
      const { success, partners } = response.data;
      if (success) dispatch(getPartnersSuccess(partners));
    } catch (error) {
      dispatch(hasError(error));
    }
  };
};

export const getPartnerTrees = (labelUrl) => {
  return async (dispatch) => {
    try {
      const response = await partnersApi.getPartnerTrees(labelUrl);
      const { success } = response.data;
      if (success) dispatch(addTreesToPartner(response.data));
      return response.data.partner;
    } catch (error) {
      console.log('Error adding trees to partner', error);
      dispatch(hasError('Error adding trees to partner', error));
    }
  };
};

export const getPartnerWinners = (labelUrl) => {
  return async (dispatch) => {
    try {
      const response = await partnersApi.getPartnerWinners(labelUrl);
      const { trees } = response.data;
      let winners = [];
      if (trees.length) {
        const partner_id = trees[0]['partner_id'];
        winners = trees[0]['winners'];
        dispatch(
          addWinnersToPartner({
            partner_id,
            winners
          })
        );
      }
      return winners;
    } catch (error) {
      console.log('Error adding winners to partner', error);
      dispatch(hasError('Error adding winners to partner', error));
    }
  };
};

export const getPartnerHoppers = (labelUrl) => {
  return async (dispatch) => {
    try {
      const response = await partnersApi.getPartnerHoppers(labelUrl);
      const { trees } = response.data;
      let hoppers = [];
      if (trees.length) {
        const partner_id = trees[0]['partner_id'];
        hoppers = trees[0]['hoppers'];
        dispatch(
          addHoppersToPartner({
            partner_id,
            hoppers
          })
        );
      }

      return hoppers;
    } catch (error) {
      console.log('Error adding Hoppers to partner', error);
      dispatch(hasError('Error adding Hoppers to partner', error));
    }
  };
};

export const updatePartner = (partnerId, partnerData) => {
  return async (dispatch) => {
    try {
      const response = await partnersApi.updatePartner(partnerId, partnerData);
      return response;
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
};

export const getRaffles = (labelUrl) => {
  return async (dispatch) => {
    try {
      const response = await partnersApi.getRaffles(labelUrl);
      const { trees } = response.data;
      let raffles = [];
      let tokens = null;
      if (trees.length) {
        const partner_id = trees[0]['partner_id'];
        raffles = trees[0]['raffles'];
        tokens = trees[0]['tokens'];
        dispatch(
          addRafflesToPartner({
            partner_id,
            raffles
          })
        );
        dispatch(
          addTokensToPartner({
            partner_id,
            tokens
          })
        );
      }

      return { raffles, tokens };
    } catch (error) {
      dispatch(hasError(error));
      throw error;
    }
  };
};
