import { createSlice } from '@reduxjs/toolkit';
import cartApi from '../../api/cart';

const initialState = {};

const slice = createSlice({
  name: 'cart',
  initialState,
  reducers: {
    // for now, we'll have always ONLY 1 partner in the cart
    partnerAddedToCart(state, action) {
      return action.payload;
    },
    quantityIncremented(state, action) {
      state.quantity = action.payload;
    },
    quantityDecremented(state, action) {
      state.quantity = action.payload > 0 ? action.payload : 1;
    },
    purchaseCompleted(state, action) {
      return initialState;
    },
    purchaseFailed(state) {
      return state;
    }
  }
});

export default slice.reducer;

/* ACTIONS */
const {
  partnerAddedToCart,
  quantityIncremented,
  quantityDecremented,
  purchaseCompleted,
  purchaseFailed
} = slice.actions;

/* ACTIONS CREATOS */
export const addPartnerToCart = (cartData) => {
  return (dispatch) => {
    dispatch(partnerAddedToCart(cartData));
  };
};

export const incrementQuantity = (quantity) => {
  return (dispatch) => dispatch(quantityIncremented(quantity));
};

export const decrementQuantity = (quantity) => {
  return (dispatch) => dispatch(quantityDecremented(quantity));
};

export const purchaseTokens = (data) => {
  return async (dispatch) => {
    try {
      const response = await cartApi.checkout(data);
      const { success, message } = response.data;

      if (!success) {
        throw message;
      }

      dispatch(purchaseCompleted());

      return response;
    } catch (error) {
      console.log('Error on cart slice', error);
      dispatch(purchaseFailed());
      throw error;
    }
  };
};
