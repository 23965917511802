import axios from './axios';

const login = ({ email, password }) => {
  return axios.post('/flow-service/user/login', {
    email,
    password
  });
};

const authWithSocial = (data) => {
  return axios.post('/flow-service/user/socialmedia', data);
};


const me = () => {
  return axios.get('/flow-service/user/cards');
};

/* This endpoint logic is wrong, backend team must look into this endpoint structure */
const updateMe = (myId, data) =>
  axios.put(`partner-management/${myId}`, data);

const object = { login, authWithSocial, me, updateMe };

export default object;
