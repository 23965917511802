import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
//
import OnboardingNavbar from './OnboardingNavbar';
import MainFooter from '../home/MainFooter';

// ----------------------------------------------------------------------

OnboardingLayout.propTypes = {
  children: PropTypes.node
};

export default function OnboardingLayout({ children }) {
  return (
    <Box>
      <OnboardingNavbar />
      <Box>{children}</Box>
      <MainFooter />
    </Box>
  );
}
