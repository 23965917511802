import { useState, useRef } from 'react';
import { Icon } from '@iconify/react';
import homeFill from '@iconify/icons-eva/home-fill';
import lockFill from '@iconify/icons-eva/lock-fill';
import personFill from '@iconify/icons-eva/person-fill';
import menu2Fill from '@iconify/icons-eva/menu-2-fill';
import { NavLink as RouterLink, useLocation } from 'react-router-dom';
// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import Link from '@material-ui/core/Link';
import AppBar from '@material-ui/core/AppBar';
import Hidden from '@material-ui/core/Hidden';
import Toolbar from '@material-ui/core/Toolbar';
import MenuItem from '@material-ui/core/MenuItem';
import Container from '@material-ui/core/Container';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

// routes
import { PATH_AUTH, PATH_PAGE } from '../../routes/paths';
// hooks
import useOffSetTop from '../../hooks/useOffSetTop';
import useAuth from '../../hooks/useAuth';

// components
import { MIconButton } from '../../components/@material-extend';
import Logo from '../../components/Logo';
import MenuPopover from '../../components/MenuPopover';
import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 60;

const RootStyle = styled(AppBar)(({ theme }) => {
  return {
    borderBottom: `1px solid ${theme.palette.primary.lighter}`,
    boxShadow: 'none',
    '& .isDesktopActive': {
      color: `${theme.palette.primary.lighter} !important`
    },
    '& .isMobileActive': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity
      )
    }
  };
});

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: { height: APP_BAR_DESKTOP }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

const DynamicLogo = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true
  });
  return <Logo color={trigger ? 'primary' : 'white'} />;
};

// ----------------------------------------------------------------------

export default function HomeNavbar() {
  const { isAuthenticated, user, logout } = useAuth();
  const onboarding = user?.onboarding;
  const url = user?.label_url;

  const anchorRef = useRef(null);
  const { pathname } = useLocation();
  const offset = useOffSetTop(100);
  const [openMenu, setOpenMenu] = useState(false);


  const MENU_LINKS = isAuthenticated ? [
   url ? { title: 'Mi Perfil', icon: personFill, href: `/${url}`} :  { title: 'Inicio', icon: personFill, href: `/`},
    onboarding ? {title:"Agenda tu cita", href:"https://calendly.com/okrabbit"} : {title: "Terminar Mi Registro", href:PATH_PAGE.onboarding}
  ] : [
    { title: 'Hazte Socio', icon: homeFill, href: '/' },
    { title: 'Login', icon: lockFill, href: PATH_AUTH.login }
  ];

  const isHome = pathname === '/';


  const renderMenuDesktop = (
    <>
      {MENU_LINKS.map((link) =>
        link.href.startsWith('http') ? (
          <Link
            underline="none"
            key={link.title}
            variant="subtitle2"
            href={link.href}
            target="_blank"
            sx={{
              mr: 5,
              color: 'common.white',
              transition: (theme) =>
                theme.transitions.create('opacity', {
                  duration: theme.transitions.duration.shortest
                }),
              '&:hover': { opacity: 0.48 },
              ...(isHome && { color: 'common.white' }),
              ...(offset && { color: 'text.primary' })
            }}
          >
            {' '}
            {link.title}
          </Link>
        ) : (
          <Link
            exact
            to={link.href}
            key={link.title}
            underline="none"
            variant="subtitle2"
            component={RouterLink}
            activeClassName="isDesktopActive"
            sx={{
              mr: 5,
              color: 'common.white',
              transition: (theme) =>
                theme.transitions.create('opacity', {
                  duration: theme.transitions.duration.shortest
                }),
              '&:hover': { opacity: 0.48 },
              ...(isHome && { color: 'common.white' }),
              ...(offset && { color: 'text.primary' })
            }}
          >
            {link.title}
          </Link>
        )
      )}
    </>
  );

  const renderMenuMobile = (
    <MenuPopover
      disablePortal
      open={openMenu}
      anchorEl={anchorRef.current}
      onClose={() => setOpenMenu(false)}
    >
      <List>
        {MENU_LINKS.map((link) => (
          <MenuItem
            exact
            to={link.href}
            key={link.title}
            component={RouterLink}
            onClick={() => setOpenMenu(false)}
            activeClassName="isMobileActive"
            sx={{ color: 'text.secondary', py: 1 }}
          >
            <ListItemIcon>
              <Icon icon={link.icon} width={20} height={20} />
            </ListItemIcon>
            <ListItemText primaryTypographyProps={{ typography: 'body2' }}>
              {link.title}
            </ListItemText>
          </MenuItem>
        ))}
      </List>
    </MenuPopover>
  );

  return (
    <div style={{ height: APP_BAR_DESKTOP }}>
      <RootStyle color="primary">
        <ToolbarStyle
          disableGutters
          sx={{
            ...(offset && {
              bgcolor: 'background.default',
              height: { md: APP_BAR_DESKTOP - 20 }
            })
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <RouterLink to="/">
              <DynamicLogo />
            </RouterLink>
            <Box sx={{ flexGrow: 1 }} />

            <Hidden mdDown>{renderMenuDesktop}</Hidden>

            <Hidden mdUp>
              <MIconButton
                ref={anchorRef}
                onClick={() => setOpenMenu(true)}
                sx={{
                  ml: 1,
                  ...(isHome && { color: 'common.white' }),
                  ...(offset && { color: 'text.primary' })
                }}
              >
                <Icon icon={menu2Fill} />
              </MIconButton>
              {renderMenuMobile}
            </Hidden>
            {isAuthenticated && <AccountPopover user={user} logout={logout} />}
          </Container>
        </ToolbarStyle>

        {offset && <ToolbarShadowStyle />}
      </RootStyle>
    </div>
  );
}
