// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

export default function Logo({ color, ...other }) {
  const logo =
    color === 'white' ? 'logo_primary_white.svg' : 'logo_primary_red.svg';
  return (
    <Box
      component="img"
      alt="logo"
      src={`/static/brand/${logo}`}
      width={150}
      {...other}
    />
  );
}
