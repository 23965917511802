import axios from './axios';

const getAll = (options = '') => {
  if (options) {
    options = '?' + new URLSearchParams(options).toString();
  }
  return axios.get(`/partner-management${options}`);
};

const getPartnerTrees = (labelUrl) =>
  axios.get(`/flow-service/partner/${labelUrl}`);

const getPartnerWinners = (partnerId) =>
  axios.get(`/flow-service/partner/${partnerId}/winners`);

const getPartnerHoppers = (partnerId) =>
  axios.get(`/flow-service/partner/${partnerId}/hoppers`);

const getRaffles = (partnerId) =>
  axios.get(`/flow-service/partner/${partnerId}/raffles`);

const updatePartner = (partnerId, data) =>
  axios.put(`/partner-management/${partnerId}`, data);

const uploadAvatar = (file, partnerId) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  formData.append('partner_id', partnerId);
  formData.append('identifier', 1);

  return axios.post('/flow-service/images/profile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const uploadCover = (file, partnerId) => {
  const formData = new FormData();
  formData.append('file', file, file.name);
  formData.append('partner_id', partnerId);
  formData.append('identifier', 2);

  return axios.post('/flow-service/images/profile', formData, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

const getSinglePartner = (uniqueName) => axios.get(`/flow-service/partner/${uniqueName}`)

const object = {
  getAll,
  getPartnerTrees,
  getPartnerWinners,
  getPartnerHoppers,
  updatePartner,
  uploadAvatar,
  uploadCover,
  getSinglePartner,
  getRaffles
};

export default object;
