import axios from './axios';

const userTypes = {
  socio: 1,
  comprador: 2
};

export const partnerTypes = {
  comercial: 1,
  donatario: 2
};

const getUser = (userId) => {
  return axios.get(`/user-management/${userId}`);
};

const updateProfile = (userId, data) =>
  axios.put(`/user-management/${userId}`, data);

const signup = (data) => axios.post(`/user-management`, data);

const object = { getUser, updateProfile, signup, userTypes, partnerTypes };

export default object;
