// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  partner: '/:label_url',
  paymentForm: '/formulario-de-pago',
  termsAndConditions: '/terminos-y-condiciones',
  privacyPolicy: '/aviso-de-privacidad',
  faqs: '/preguntas-frecuentes',
  onboarding: '/onboarding',
  settings: '/settings' //This toute has to be the last
};
