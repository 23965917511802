// mock api
import './_apis_';

// highlight
import './utils/highlight';

// scroll bar
import 'simplebar/src/simplebar.css';

// lightbox
import 'react-image-lightbox/style.css';

// slick-carousel
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// lazy image
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import 'lazysizes/plugins/parent-fit/ls.parent-fit';

import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';

import TagManager from 'react-gtm-module';
import { GOOGLE_TAG_MANAGER } from './config';

const tagManagerArgs = {
  gtmId: GOOGLE_TAG_MANAGER
};

TagManager.initialize(tagManagerArgs);

// ----------------------------------------------------------------------

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
