import axios from './axios';

const addCard = (data) => {
  return axios.post(`/flow-service/customer-cards`, data);
};

const getCards = () => {
  return axios.get('/flow-service/user/cards');
};

const removeCard = (id) => {
  return axios.delete(`/card-management/cards/${id}`);
};

const getSubscriptions = () => {
  return axios.get('/flow-service/user/subscriptions?status=active');
};

const removeSubscription = (id) => {
  return axios.delete(`/subscription-management/${id}`);
};

const object = {
  addCard,
  getCards,
  removeCard,
  getSubscriptions,
  removeSubscription
};

export default object;
