import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

// redux
import {
  login,
  signup,
  logout,
  authWithGoogle,
  authWithFacebook
} from '../redux/slices/auth';

// ----------------------------------------------------------------------

useAuth.propTypes = {
  method: PropTypes.oneOf(['jwt', 'firebase'])
};

export default function useAuth() {
  // JWT Auth
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated } = useSelector(
    (state) => state.authJwt
  );

  // JWT Auth

  return {
    method: 'jwt',
    user,
    isLoading,
    isAuthenticated,

    login: ({ email, password }) => dispatch(login({ email, password })),
    signup: (data) => dispatch(signup(data)),
    authWithGoogle: (values) => dispatch(authWithGoogle(values)),
    authWithFacebook: (values) => dispatch(authWithFacebook(values)),
    logout: () => dispatch(logout()),
  };
}
