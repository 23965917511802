// ----------------------------------------------------------------------

export default function Progress(theme) {
  return {
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 2,
          overflow: 'hidden',
          height: 20
        },
        bar: {
          transform: 'translate(0)!important',
          background:
            'linear-gradient(270.01deg,#ED1C24 -34.07%,#FFE600 31.57%,#16D635 100%)'
        },
        colorPrimary: {
          backgroundColor: '#f4f4f4'
        },
        buffer: {
          backgroundColor: 'transparent'
        }
      }
    }
  };
}
