// material
import { alpha, experimentalStyled as styled } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Container from '@material-ui/core/Container';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';

// hooks
import useOffSetTop from '../../hooks/useOffSetTop';

// components
import Logo from '../../components/Logo';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 60;

const RootStyle = styled(AppBar)(({ theme }) => {
  return {
    borderBottom: `1px solid ${theme.palette.primary.lighter}`,
    boxShadow: 'none',
    '& .isDesktopActive': {
      color: `${theme.palette.primary.lighter} !important`
    },
    '& .isMobileActive': {
      color: theme.palette.primary.main,
      fontWeight: theme.typography.fontWeightMedium,
      backgroundColor: alpha(
        theme.palette.primary.main,
        theme.palette.action.selectedOpacity
      )
    }
  };
});

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  height: APP_BAR_MOBILE,
  transition: theme.transitions.create(['height', 'background-color'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter
  }),
  [theme.breakpoints.up('md')]: { height: APP_BAR_DESKTOP }
}));

const ToolbarShadowStyle = styled('div')(({ theme }) => ({
  left: 0,
  right: 0,
  bottom: 0,
  height: 24,
  zIndex: -1,
  margin: 'auto',
  borderRadius: '50%',
  position: 'absolute',
  width: `calc(100% - 48px)`,
  boxShadow: theme.customShadows.z8
}));

const DynamicLogo = () => {
  const trigger = useScrollTrigger({
    disableHysteresis: true
  });
  return <Logo color={trigger ? 'primary' : 'white'} />;
};

// ----------------------------------------------------------------------

export default function HomeNavbar() {
  const offset = useOffSetTop(100);

  return (
    <div style={{ height: APP_BAR_DESKTOP }}>
      <RootStyle color="primary">
        <ToolbarStyle
          disableGutters
          sx={{
            ...(offset && {
              bgcolor: 'background.default',
              height: { md: APP_BAR_DESKTOP - 20 }
            })
          }}
        >
          <Container
            maxWidth="lg"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}
          >
            <DynamicLogo />

            <Box sx={{ flexGrow: 1 }} />
          </Container>
        </ToolbarStyle>

        {offset && <ToolbarShadowStyle />}
      </RootStyle>
    </div>
  );
}
