import { createSlice } from '@reduxjs/toolkit';

import billingApi from '../../api/billing';

const initialState = {
  cards: [],
  subscriptions: []
};

const slice = createSlice({
  name: 'billing',
  initialState,
  reducers: {
    cardAdded: (state, action) => {
      state.cards.push(action.payload);
      state.cards.sort((a, b) => a.id - b.id);
    },
    cardsFetched: (state, action) => {
      state.cards = action.payload;
    },
    cardDeleted: (state, action) => {
      state.cards = state.cards.filter((item) => item.id !== action.payload);
    },

    /* SUBSCRIPTIONS */
    subscriptionAdded: (state, action) => {
      state.subscriptions.push(action.payload);
      state.subscriptions.sort((a, b) => a.id - b.id);
    },
    subscriptionsFetched: (state, action) => {
      state.subscriptions = action.payload;
    },
    subscriptionDeleted: (state, action) => {
      state.subscriptions = state.subscriptions.filter(
        (item) => item.id !== action.payload
      );
    }
  }
});

export default slice.reducer;

const {
  cardAdded,
  cardsFetched,
  cardDeleted,
  subscriptionsFetched,
  subscriptionDeleted,
  subscriptionAdded
} = slice.actions;

/* INTERNAL USE ONLY */
const getCard = (id) => {
  return (_, getState) => {
    const { billing } = getState();
    const { cards } = billing;
    return cards.find((item) => item.id === id);
  };
};

/* ACTION CREATORS */
export const addCard = (cardData) => {
  return async (dispatch) => {
    try {
      const response = await billingApi.addCard(cardData);

      const { card } = response.data;
      dispatch(cardAdded(card));
      return response;
    } catch (error) {
      throw error;
    }
  };
};

export const getCards = () => {
  return async (dispatch) => {
    try {
      const response = await billingApi.getCards();
      const { cards } = response.data;
      dispatch(cardsFetched(cards));
      return response;
    } catch (error) {
      console.log('Error getting cards', error);
      throw error;
    }
  };
};

export const deleteCard = (id) => {
  return async (dispatch) => {
    //save card object from state in case request fails
    const cardToDelete = dispatch(getCard(id));
    try {
      //Remove temporarily no matter the request response
      dispatch(cardDeleted(id));

      // send remove card request
      const response = await billingApi.removeCard(id);
      return response;
    } catch (error) {
      //rollback to state, add the card we could't delete
      dispatch(cardAdded(cardToDelete));
      throw error;
    }
  };
};

/* SUBSCRIPTIONS */
export const getSubscriptions = () => {
  return async (dispatch) => {
    try {
      const response = await billingApi.getSubscriptions();
      const { subscriptions } = response.data;
      dispatch(subscriptionsFetched(subscriptions));
      return response;
    } catch (error) {
      console.log('Error getting subscriptions', error);
      throw error;
    }
  };
};

export const deleteSubscription = (id) => {
  return async (dispatch, getState) => {
    //get subscription object from state in case request fails
    const currentState = getState();
    const { subscriptions } = currentState.billing;

    const subscriptionToDelete = subscriptions.find((item) => item.id === id);
    try {
      //Remove temporarily no matter the request response, it makes the system seem like it's fast
      dispatch(subscriptionDeleted(id));

      // send remove card request
      const response = await billingApi.removeSubscription(id);
      return response;
    } catch (error) {
      //rollback to state, add the subscription we could't delete
      dispatch(subscriptionAdded(subscriptionToDelete));
      throw error;
    }
  };
};

/* Only push the new subscription to state, subscription has been added from backend side */
export const pushSubscription = (subscriptionData) => {
  return (dispatch) => {
    try {
      dispatch(subscriptionAdded(subscriptionData));
      return subscriptionData;
    } catch (error) {
      throw error;
    }
  };
};

/* Only push the new card to state, card has been added from backend side */
export const pushCard = (cardData) => {
  return (dispatch) => {
    try {
      dispatch(cardAdded(cardData));
      return cardData;
    } catch (error) {
      throw error;
    }
  };
};
