import { useEffect } from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { HelmetProvider } from 'react-helmet-async';
import { Provider as ReduxProvider } from 'react-redux';
import { PersistGate } from 'redux-persist/lib/integration/react';
// theme
import ThemeConfig from './theme';
// routes
import routes, { renderRoutes } from './routes';
// redux
import { store, persistor } from './redux/store';

// components
import NotistackProvider from './components/NotistackProvider';
import LoadingScreen from './components/LoadingScreen';

import JwtProvider from './components/authentication/JwtProvider';

// Environtment Vars
import { OPENPAY_MERCHANT_ID, OPENPAY_API_KEY, NODE_ENV } from './config';

// ----------------------------------------------------------------------

const history = createBrowserHistory();

export default function App() {
  /* Open Pay Load Keys */
  useEffect(() => {
    window.OpenPay.setId(OPENPAY_MERCHANT_ID);
    window.OpenPay.setApiKey(OPENPAY_API_KEY);
    window.OpenPay.setSandboxMode(NODE_ENV === 'development');
  }, []);

  return (
    <HelmetProvider>
      <ReduxProvider store={store}>
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <ThemeConfig>
            <NotistackProvider>
              <Router history={history}>
                <JwtProvider>{renderRoutes(routes)}</JwtProvider>
              </Router>
            </NotistackProvider>
          </ThemeConfig>
        </PersistGate>
      </ReduxProvider>
    </HelmetProvider>
  );
}
