import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
// hooks
import useAuth from '../hooks/useAuth';
import LoadingScreen from '../components/LoadingScreen';

// ----------------------------------------------------------------------

OnboardingGuard.propTypes = {
  children: PropTypes.node
};

/* THIS IS USED ONLY FOR login ROUTE, IN CASE THE USER IS LOGGED IN, THE SYSTEM WILL REDIRECT HIM TO THE DASHBOARD */

export default function OnboardingGuard({ children }) {
  const { isAuthenticated, user } = useAuth();

  useEffect(() => {
    if (!user) return;
  }, [user]);

  if (!isAuthenticated) {
    return <Redirect to={'/'} />;
  }

  if (!user) {
    return <LoadingScreen />;
  }

  if (!!user?.onboarding) {
    return <Redirect to={'/'} />;
  }

  return <>{children}</>;
}
