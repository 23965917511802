import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';
//
import HomeNavbar from './HomeNavbar';
import MainFooter from './MainFooter';

// ----------------------------------------------------------------------

HomeLayout.propTypes = {
  children: PropTypes.node
};

export default function HomeLayout({ children }) {
  return (
    <Box>
      <HomeNavbar />
      <Box>{children}</Box>
      <MainFooter />
    </Box>
  );
}
