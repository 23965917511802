export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;
export const OPENPAY_MERCHANT_ID =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_OPENPAY_MERCHANT_ID_DEV
    : process.env.REACT_APP_OPENPAY_MERCHANT_ID_DEV;
export const OPENPAY_API_KEY =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_OPENPAY_API_KEY_DEV
    : process.env.REACT_APP_OPENPAY_API_KEY_DEV;
//export const NODE_ENV = process.env.NODE_ENV;
export const NODE_ENV = 'development';

export const GOOGLE_TAG_MANAGER =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_GTM_DEV
    : process.env.REACT_APP_GTM_DEV;

export const apiServer =
  process.env.NODE_ENV === 'development'
    ? 'https://api-pre.okrabbit.mx/api/0.2'
    : 'https://api-pre.okrabbit.mx/api/0.2';

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID
};

export const ACCOUNT_TYPES = {
  partner: 1,
  buyer: 2
};
